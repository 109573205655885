import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <div>
        <h2>GOT QUESTIONS?</h2>
        <p>
        The easiest thing to do is email <a href="mailto:camron.weeks@gmail.com">me</a>.
        </p>
      </div>
    );
  }
}

export default Contact;